<template>
    <b-row>
        <b-col cols="12">
            <b-card v-if="intervention">
                <b-row class="m-0">
                    <b-col xl="4">
                        <b-form-group label="Nº Intervención" label-for="intervention_id">
                            <b-form-input type="text" v-model="intervention.id" disabled />
                        </b-form-group>
                    </b-col>
                    <b-col xl="4">
                        <b-form-group label="Nº Equipo" label-for="equipment_id">
                            <b-form-input type="text" placeholder="Nº Equipo" autocomplete="off"
                                v-model="intervention.equipment_id" disabled />
                        </b-form-group>
                    </b-col>

                    <b-col xl="4">
                        <b-form-group label="Urgencia" label-for="account-roles">
                            <v-select v-model="intervention.urgent" label="name" :options="urgenOptions"
                                :reduce="(option) => option.id">
                                <template #no-options="{}">
                                    No hay tipos de urgencia.
                                </template>
                            </v-select>
                            <small v-if="validation.urgent" class="text-danger w-100">
                                {{ validation.urgent }}
                            </small>
                        </b-form-group>
                    </b-col>
                    <b-col xl="4">
                        <b-form-group label="TAG" label-for="account-roles">
                            <v-select v-model="intervention.tag_id" label="code" :options="tags"
                                :reduce="(option) => option.id">
                                <template #no-options="{}">
                                    No hay TAGs.
                                </template>
                            </v-select>
                            <small v-if="validation.tag_id" class="text-danger">
                                {{ validation.tag_id }}
                            </small>
                        </b-form-group>
                    </b-col>
                    <b-col xl="12">
                        <b-form-group label="Observaciones" label-for="">
                            <b-form-textarea v-model="intervention.description" type="text" placeholder="Observaciones"
                                autocomplete="off" />
                            <small v-if="validation.description" class="text-danger">
                                {{ validation.description }}
                            </small>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" xl="4">
                        <b-form-group label="Estado" label-for="">
                            <v-select v-model="intervention.intervention_states_id" label="name" :options="states"
                                :disabled="true" :reduce="(option) => option.id">
                                <template #no-options="{}">
                                    No hay estados.
                                </template>
                            </v-select>
                        </b-form-group>
                        <small v-if="validation.urgent" class="text-danger w-100">
                            {{ validation.intervention_states_id }}
                        </small>
                    </b-col>
                </b-row>
                <b-row class="m-0" align-h="start">
                    <b-col cols="12">
                        <b-button variant="primary" class="mt-2 mr-1" @click="updateData"
                            :disabled="intervention.closed ? true : false">
                            Actualizar Cambios
                        </b-button>
                        <b-button variant="outline-secondary" type="reset" class="mt-2" @click="cancel">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { BCard, BCol, BButton, BRow, BFormGroup, BFormInput, BFormTextarea } from 'bootstrap-vue'
import { workCenters } from "@/constants/workCenters"
import helpers from "@/resources/helpers/helpers"
import { baseUrl } from "@/constants/app"
import vSelect from "vue-select"
import { mapState } from "vuex"
import { createHelpers } from "vuex-map-fields"

const { mapFields } = createHelpers({
    getterType: 'equipment/getField',
    mutationType: 'equipment/updateField',
})

export default {
    name: "FormView",
    components: {
        BCol,
        BRow,
        BCard,
        BButton,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        vSelect,
    },
    data() {
        return {
            urgenOptions: [
                {
                    id: 1,
                    name: 'Urgente',
                },
                {
                    id: 0,
                    name: 'No urgente',
                }
            ],
            showLoading: false,
            workCenter: [],
            baseUrl,
            workCenters,
            helpers,
            states: [],
        }
    },
    computed: {
        ...mapState('intervention', ['validation', 'intervention']),
        ...mapState('tag', ['tags']),
        ...mapFields({ equipment: 'equipment' }),
    },
    created() {
        this.getIntervention()
        this.getStates()
    },
    methods: {
        getIntervention() {
            this.showLoading = true

            this.$store.dispatch('intervention/getIntervention', {
                id: this.$route.params.id,
                relations: ['equipment', 'user', 'tag'],
            }).then(response => {
                this.showLoading = false
            }).catch(error => {
                this.showLoading = false
                console.log(error)
                helpers.showToast(error.message, 'MonitorIcon', 'danger')
            })
        },
        getStates() {
            this.showLoading = true

            this.$store.dispatch('interventionStates/getStates').then(response => {
                this.states = response
                this.showLoading = false
            }).catch(error => {
                this.showLoading = false
                console.log(error)
                helpers.showToast(error.message, 'MonitorIcon', 'danger')
            })
        },
        updateData() {
            this.intervention.id = this.$route.params.id;
            this.$store.dispatch('intervention/update', this.intervention)
                .then(response => {
                    helpers.showToast('Equipo actualizado correctamente', 'CheckIcon', 'success')
                })
                .catch(error => {
                    console.log(error)
                })
        },
        cancel() {
            this.$router.push({ name: 'sheet_equipment', id: this.$route.params.client })
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
